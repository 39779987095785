import React from "react";

import Layout from "../components/Layout";
import SEO from "../components/SEO";
import Section from "../components/Section";
import Nav from "../components/Nav";
import Article from "../components/Article";
import H1 from "../components/H1";
import Paragraph from "../components/Paragraph";

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Nav />
    <Section>
      <Article isCentered>
        <H1>We're sorry</H1>
        <Paragraph isLarge>We couldn't find the page you were looking for.</Paragraph>
        <Paragraph>
          This is either because there is an error in the URL entered into your web browser, in
          which case we suggest you check the URL and try again. It's also possible the page you are
          looking for has been moved or deleted.
        </Paragraph>
      </Article>
    </Section>
  </Layout>
);

export default NotFoundPage;
